<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">BRDF Measurement  Database </font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><p>For each of the 61 samples measured, the BRDF was measured by recording images of the sample under 205 different combinations of viewing and illumination directions. The recorded images were <router-link to="/repository/CURET/cal"> radiometrically calibrated</router-link> to get radiance and BRDF from pixel values. A <router-link to="/repository/CURET/table"> table </router-link> is provided which gives the illumination and viewing direction associated with the 205 measurements. </p>
              <p></p>
 <p> The ascii file <a href="https://cave.cs.columbia.edu/old/software/curet/data/abrdf.dat"> abrdf.dat </a> gives the measurements in the following format
 </p><pre>1 meas1 meas2 . . . meas205
2 meas1 meas2 . . . meas205
.
.
.
61 meas1 meas2 . . . meas205
 </pre>
where the first number in each row is the sample number (1-61) and the subsequent numbers are the 205 BRDF measurements for that particular sample averaged over red, green, and blue. Similarly, <a href="https://cave.cs.columbia.edu/old/software/curet/data/rbrdf.dat"> rbrdf.dat </a>, <a href="https://cave.cs.columbia.edu/old/software/curet/data/gbrdf.dat"> gbrdf.dat </a> and <a href="https://cave.cs.columbia.edu/old/software/curet/data/bbrdf.dat">bbrdf.dat</a> give the red, green and blue brdf measurements, respectively, in an analogous format.
<p></p>
<p> Rendered <router-link to="/repository/CURET/spheres"> spheres</router-link>, obtained using the measured radiance values, are useful for viewing the measurement results. </p>
<p>
</p><h4><router-link to="/repository/CURET/over"> A Note on Overflow Issues</router-link></h4>
</td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                  </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>